<template>
    <div class>
        <div class="text-gray-400 title text-left duration-500 pc_show">积分兑换</div>
        <div class="pc_show my-3">
            <div class="text-left text-gray-500">
                <span @click="goBack" class="cursor-pointer">
                    &lt;
                    <span class="pl-1">返回</span>
                </span>
            </div>
        </div>
        <div class="flex content">
            <div class="productImg">
                <img :src="getExchangeProduct.productsImage" alt />
            </div>
            <div class="pl-2 text-left left_box">
                <div class="font-bold text-base ex_text">商品名称：{{ getExchangeProduct.productsName }}</div>
                <div
                    class="sm:my-2 ex_text"
                >商品描述：{{ getExchangeProduct.productsDescribe }}阿看拉时代峰峻as家得福阿来上课家得福</div>
                <div class="ex_text">
                    所需积分：
                    <span
                        class="text-primary text-lg font-bold"
                    >{{ getExchangeProduct.productsIntegral }}</span>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <el-form ref="form" label-width="80px" label-position="left">
                <el-form-item label="收货地址" style="text-align:left">
                    <!-- <el-select v-model="form.channel" placeholder="请选择" style="width:100%">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                    </el-select>-->
                    <div class="pc_select_show">
                        <el-select
                            v-model="address"
                            placeholder="请选择"
                            style="width:100%;max-width: 400px;"
                        >
                            <el-option
                                v-for="item in getAddressList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="phone_select_show">
                        <div
                            class="form_input custom_input flex"
                            @click="openPopup"
                            style="width:100%"
                        >
                            <span
                                :class="address == '' ? 'text-gray-300' : ''"
                                class="address_span"
                            >
                                {{
                                    address == "" ? "请选择" : tramsfromAddress(address)
                                }}
                            </span>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="." class="flex justify-start btn_item">
                    <el-button type="primary" class="btn duration-500" @click="exchangeProduct">确定兑换</el-button>
                    <!-- <el-button style="width:200px">解绑</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <MobilePopup ref="mobilepopup" :list="getAddressList" @select="selectFunc"></MobilePopup>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            address: "",
            addressList: []
        };
    },
    created() {
        if (!this.getExchangeProduct.productsImage) {
            this.goBack()
        } else {
            this.$store.dispatch("aquireAddress")
        }
    },
    computed: {
        ...mapGetters(['getExchangeProduct', 'getAddressList'])
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        openPopup() {
            this.$refs.mobilepopup.open();
        },
        selectFunc(item) {
            this.address = item.value
        },
        tramsfromAddress(address) {
            let index = this.getAddressList.findIndex(item => {
                return item.value == address
            })
            return this.getAddressList[index].label
        },
        // 积分兑换商品
        exchangeProduct() {
            if (this.isNull(this.address)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请选择收货地址' })
                return
            }
            this.$request({
                method: "post",
                url: this.$requestPath.exchangeProduct,
                data: {
                    integralProductsId: this.getExchangeProduct.integralProductsId,
                    receiptId: this.address
                }
            }).then(res => {
                console.log("积分兑换商品", res)
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: "商品兑换成功" })
                    setTimeout(() => {
                        this.goBack()
                    }, 1000)
                } else {
                    this.$message.error(res.msg);
                }

            }).catch(error => {
                console.log("获取积分兑换纪录", error)
            })
        },
    },
};
</script>

<style lang='scss' scoped>
@media screen and (max-width: 640px) {
    .pc_show {
        display: none;
    }
    .productImg {
        width: 5.625rem;
    }
    .left_box {
        width: calc(100% - 5.625rem);
    }
    .content {
        margin-top: 1.25rem;
    }
    .ex_text {
        font-size: 0.8125rem;
    }
}
@media screen and (min-width: 640px) {
    .title {
        font-size: 1.875rem;
    }
    .pc_show {
        display: block;
    }
    .productImg {
        width: 12.5rem;
    }
    .left_box {
        width: calc(100% - 12.5rem);
    }
    .btn {
        width: 12.5rem;
    }
}
</style>